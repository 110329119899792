import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { AppBar, Toolbar, IconButton, Typography, Container, Grid, Card, CardMedia, CardContent, CircularProgress, Snackbar, Link, Drawer, List, ListItem, ListItemText, Badge, Box, useMediaQuery } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Analytics } from "@vercel/analytics/react";
import { styled } from '@mui/system';
import Cookies from 'js-cookie';

const API_KEY = "ec1a50a2fbf346257a98773dd84845a5";
const AFFILIATE_ID = "39576-001";
const BASE_URL = 'https://api.sokmil-ad.com/api/v1/Item';
const HITS_PER_PAGE = 20;
const INITIAL_LOADS = 10; // 最初に10回分のデータを取得

const CustomAppBar = styled(AppBar)({
  backgroundColor: '#3b3b3b',
  color: '#fff',
});

const CustomCard = styled(Card)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxWidth: 345,
  margin: 'auto',
});

const CustomCardMedia = styled(CardMedia)({
  height: 300,
});

const CustomCardContent = styled(CardContent)({
  flexGrow: 1,
  overflow: 'hidden',
});


function App() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const itemsPerPage = isMobile ? 5 : 20;

  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [availableGenres, setAvailableGenres] = useState([]);
  const [favorites, setFavorites] = useState(() => {
    const savedFavorites = Cookies.get('favorites');
    return savedFavorites ? JSON.parse(savedFavorites) : [];
  });
  const loader = useRef(null);
  const [page, setPage] = useState(1);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [genreDrawerOpen, setGenreDrawerOpen] = useState(false);

  useEffect(() => {
    Cookies.set('favorites', JSON.stringify(favorites), { expires: 365 });
  }, [favorites]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const fetchMovies = useCallback(async (genres = [], newPage = 1) => {
    setLoading(true);
    setError(null);
    try {
      let allItems = [];
      for (let i = 0; i < INITIAL_LOADS; i++) {
        const params = {
          api_key: API_KEY,
          affiliate_id: AFFILIATE_ID,
          output: 'json',
          hits: HITS_PER_PAGE,
          offset: i * HITS_PER_PAGE + 1,
        };

        if (genres.length > 0) {
          params.genre = genres.join(',');
        }

        const response = await axios.get(BASE_URL, { params });
        console.log("API Response Size:", (JSON.stringify(response.data).length / (1024 * 1024)).toFixed(2) + " MB");
        if (response.data && response.data.result && Array.isArray(response.data.result.items)) {
          const newItems = response.data.result.items.filter(
            item => item.sampleMovieURL && item.sampleMovieURL.url
          );
          allItems = allItems.concat(newItems);
        } else {
          console.error("Unexpected response format", response.data);
          setError("Unexpected response format");
          break;
        }
      }

      const shuffledItems = shuffleArray(allItems);
      setMovies(shuffledItems);

      const genresSet = new Set();
      shuffledItems.forEach(item => {
        if (item.iteminfo && item.iteminfo.genre) {
          item.iteminfo.genre.forEach(genre => genresSet.add(genre.name));
        }
      });
      setAvailableGenres([...genresSet]);

    } catch (error) {
      console.error("Error fetching data from Sokmil", error);
      setError("Error fetching data from Sokmil: " + error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMovies(selectedGenres);
  }, [fetchMovies, selectedGenres]);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prevPage) => prevPage + 1);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0.1
    };
    const observer = new IntersectionObserver(handleObserver, option);
    const currentLoader = loader.current;
    if (currentLoader) {
      observer.observe(currentLoader);
    }
    return () => {
      if (currentLoader) {
        observer.unobserve(currentLoader);
      }
    };
  }, [handleObserver]);

  const toggleGenre = (genre) => {
    setSelectedGenres((prevSelectedGenres) => {
      const newSelectedGenres = prevSelectedGenres.includes(genre)
        ? prevSelectedGenres.filter((g) => g !== genre)
        : [...prevSelectedGenres, genre];
      setPage(1);
      setMovies([]);
      fetchMovies(newSelectedGenres, 1);
      return newSelectedGenres;
    });
    setGenreDrawerOpen(false);
  };

  const toggleFavorite = (movie) => {
    setFavorites((prevFavorites) => {
      const isFavorite = prevFavorites.some(fav => fav.id === movie.id);
      if (isFavorite) {
        return prevFavorites.filter(fav => fav.id !== movie.id);
      } else {
        return [...prevFavorites, { id: movie.id, title: movie.title, affiliateURL: movie.affiliateURL }];
      }
    });
  };

  const displayedMovies = movies.slice(0, page * itemsPerPage);

  useEffect(() => {
    if (isMobile) {
      // const script = document.createElement('script');
      // script.src = "https://adm.shinobi.jp/s/c976aca09231014f9c2e867a7213217c";
      // script.async = true;
      // document.body.appendChild(script);

      // return () => {
      //   document.body.removeChild(script);
      // };
    }
  }, [isMobile]);

  return (
    <>
      <CustomAppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            サンプルマスター
          </Typography>
          <>
            <IconButton color="inherit" onClick={() => setGenreDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={genreDrawerOpen} onClose={() => setGenreDrawerOpen(false)}>
              <Box p={2}>
                <Typography variant="h6">ジャンルを選択</Typography>
                <IconButton onClick={() => setGenreDrawerOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <List>
                {availableGenres.map((genre) => (
                  <ListItem button key={genre} onClick={() => toggleGenre(genre)}>
                    <ListItemText primary={genre} />
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </>
          <IconButton color="inherit" onClick={() => setDrawerOpen(true)}>
            <Badge badgeContent={favorites.length} color="error">
              {drawerOpen ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </Badge>
          </IconButton>
        </Toolbar>
      </CustomAppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box display="flex" justifyContent="flex-end" p={1}>
          <IconButton onClick={() => setDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {favorites.length > 0 ? (
            favorites.map((fav, index) => (
              <ListItem button key={`${fav.id}-${index}`} onClick={() => window.open(fav.affiliateURL, '_blank')}>
                <ListItemText primary={fav.title} />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="お気に入りがありません" />
            </ListItem>
          )}
        </List>
      </Drawer>
      <Container maxWidth="lg" style={{ marginTop: '2rem', textAlign: 'center' }}>
        <Grid container spacing={2}>
          {displayedMovies.map((movie, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={`${movie.id}-${index}`}>
              <CustomCard>
                {movie.sampleMovieURL && movie.sampleMovieURL.url ? (
                  <CustomCardMedia
                    component="iframe"
                    src={movie.sampleMovieURL.url}
                    title={movie.title}
                  />
                ) : (
                  <CustomCardContent>
                    <Typography variant="h6">
                      動画がありません
                    </Typography>
                  </CustomCardContent>
                )}
                <CustomCardContent>
                  <Link href={movie.affiliateURL} target="_blank" rel="noopener noreferrer">
                    <Typography variant="h6">
                      {movie.title}
                    </Typography>
                  </Link>
                  {movie.iteminfo && movie.iteminfo.genre && (
                    <Typography variant="body2" color="textSecondary">
                      ジャンル: {movie.iteminfo.genre.map(genre => genre.name).join(', ')}
                    </Typography>
                  )}
                </CustomCardContent>
                <IconButton onClick={() => toggleFavorite(movie)}>
                  {favorites.some(fav => fav.id === movie.id) ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
                </IconButton>
              </CustomCard>
            </Grid>
          ))}
        </Grid>
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            <CircularProgress />
          </div>
        )}
        {error && (
          <Snackbar
            open={true}
            message={error}
            onClose={() => setError(null)}
          />
        )}
        <div ref={loader} style={{ height: '20px', margin: '10px 0' }} />
        <div style={{ marginTop: '2rem', textAlign: 'center' }}>
          <a href="https://sokmil-ad.com/" target="_blank" rel="noopener noreferrer">
            <img src="https://sokmil-ad.com/api/credit/88x31.gif" alt="WEB SERVICE BY SOKMIL" width="88" height="31" border="0" />
          </a>
          <a href="https://sokmil-ad.com/" target="_blank" rel="noopener noreferrer">
            <img src="https://sokmil-ad.com/api/credit/135x18.gif" alt="WEB SERVICE BY SOKMIL" width="135" height="18" border="0" />
          </a>
        </div>
      </Container>
      <Analytics />
    </>
  );
}

export default App;
